body {
    margin: 0;
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html {
    margin: 0;
    padding: 0;
}
* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ----------------------------------- */

.ant-btn-default {
    font-weight: 600;
    height: 40px;
    line-height: 20px;
    padding: 10px 24px;
    border: 1px solid #e6e8f0;

    &:hover:not(:disabled) {
        background-color: #fafbfc;
        color: #001965 !important;
        border: 1px solid #e6e8f0 !important;
    }
    &:active:not(:disabled) {
        background-color: #ffffff;
        color: #001965 !important;
        border: 1px solid #001965 !important;
    }
    &:disabled {
        background-color: transparent;
        color: #b3bad1;
        border: 1px solid #e6e8f0 !important;
    }
}

.ant-btn-primary {
    font-weight: 600;
    height: 40px;
    line-height: 20px;
}

.ant-btn-text {
    font-weight: 600;
    height: 40px;
    line-height: 20px;
}
.ant-btn-link {
    font-weight: 600;
    height: 40px;
    line-height: 20px;
}

.ant-input[type='password'] {
    color: #001965 !important;
}

.small-input-wrapper {
    width: 48%;
}
header {
    height: 72px;
}

form {
    width: 100%;
}

.input-wrapper {
    width: 100%;
}

.input-wrapper-multiselect {
    width: 100%;

    &.with-checkboxes {
        width: 300px;
    }
}

.red {
    color: #e80a0f;
}

.error-text {
    color: #e80a0f;
    font-size: 14px;
    margin: 5px 0 0 0;
    font-weight: 500;
}

.input {
    border-radius: 40px;
    height: 52px;
    border: 1px solid #e6e8f0;
    padding: 8px 20px 8px 20px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    transition: all 0.4s;
}

.input-datepicker {
    border-radius: 40px;
    height: 52px;
    border: 1px solid #e6e8f0;
    padding: 19px 20px 8px 48px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    transition: all 0.4s;

    &.noImage {
        padding: 19px 20px 8px 19px;
    }
}

.range {
    .input-datepicker {
        height: 42px;
        padding: 10px 20px 8px 48px;
    }

    .as-placeholder-meeting {
        top: 10px !important;
    }

    .arrow-datepicker {
        width: 19px;
        top: 11px !important;
        right: 20px;
    }

    .calendar-image {
        top: 12px !important;
    }
}

.ant-input:focus {
    // border: 1px solid #001965 !important;
    box-shadow: none !important;
}
.ant-input:hover {
    border: 1px solid #b3bad1;
}

.textarea {
    border-radius: 26px;
    border: 1px solid #e6e8f0;
    padding: 26px 11px 11px 9px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Quicksand', sans-serif;
    transition: all 0.4s;

    &.red {
        border: 1px solid #e80a0f;
    }

    &:hover {
        border: 1px solid #b3bad1;
    }

    &.focus {
        border: 1px solid #001965;
    }
}
.ant-input-borderless {
    border: none;
    padding-top: 0;
    color: #001965 !important;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}
.ant-input-borderless:hover {
    border: none;
    padding-top: 0;
    color: #001965 !important;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.input-date {
    border-radius: 40px;
    height: 40px;
    border: none;
    padding: 8px 20px 7px 0px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    transition: all 0.4s;
    display: flex;
    align-items: center;
    margin: 0 0 8px 0;

    img {
        margin: 0 10px 0 0;
    }
}

.err {
    border: 1px solid #ff4d4f;
}

.input:hover:not(:focus) {
    border: 1px solid #b3bad1;
}

.focus {
    border: 1px solid #001965 !important;
}

.input:focus {
    box-shadow: none !important;
    padding-top: 26px;
}

.ant-picker-focused {
    box-shadow: none !important;
    border: 1px solid #001965 !important;
}

.ant-picker-header-super-next-btn {
    display: none !important;
}
.ant-picker-header-super-prev-btn {
    display: none !important;
}

.input:not(:placeholder-shown) {
    padding-top: 22px;
}

.ant-input-affix-wrapper > input.ant-input {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.ant-input-affix-wrapper-focused {
    border: 1px solid #001965 !important;
    box-shadow: none !important;
}

.ant-input-suffix svg {
    fill: #808cb2;
}

.ant-message .ant-message-notice .ant-message-notice-content {
    padding: 16px;
    color: #001965;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked) .ant-checkbox-inner {
    border-color: #e6e8f0;
}
.ant-checkbox-wrapper:not(:has(.ant-checkbox-indeterminate)):hover .ant-checkbox-inner {
    border-color: #b3bad1 !important;
}

.ant-checkbox-indeterminate {
    .ant-checkbox-inner {
        border-color: #001965 !important;
        width: 20px;
        height: 20px;
        background-color: #001965 !important;
        &::after {
            background-color: #ffffff;
            width: 10px;
            height: 2px;
        }
    }
}

.ant-message .ant-message-notice .ant-message-notice-content:has(.ant-message-success) {
    background-color: #e6f4e8;
}
.ant-message .ant-message-notice .ant-message-notice-content:has(.ant-message-error) {
    background-color: #fde6e7;
}
.ant-message .ant-message-notice .ant-message-notice-content:has(.ant-message-info) {
    background-color: #eaeff5;
}

.ant-form-item {
    margin-bottom: 16px;
    .ant-form-item-explain {
        margin: 8px 0 16px 0;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
}

.contract-value-input {
    padding: 22px 26px 2px 40px;
}

.additional-input {
    width: 100%;
    height: 32px;
    border: 1px solid #e6e8f0;
    border-radius: 24px;
    color: #001965;
    font-weight: 500;
    padding: 0px 85px 0 14px;
}
.additional-input-err {
    width: 100%;
    height: 32px;
    border: 1px solid #e80a0f;
    border-radius: 24px;
    color: #001965;
    font-weight: 500;
    padding: 0px 85px 0 14px;
}
.additional-input-errtext {
    color: #e80a0f;
    font-size: 14px;
    font-weight: 500;
}

.additional-input:hover {
    border: 1px solid #b3bad1;
    outline: none;
    box-shadow: none;
}

.additional-input:focus {
    border: 1px solid #001965;
    outline: none;
    box-shadow: none;
}

.ant-form-item-control-input {
    min-height: 52px !important;
}

.ant-input-suffix {
    margin: 0 0 16px 0;
}

.loader {
    display: inline-block;
    position: relative;
    width: 59px;
    height: 9px;
}

.loader span {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    animation: loaderAnimation 1.8s infinite ease-in-out;
}

.loader span:nth-child(1) {
    left: 13px;
    animation-delay: 0s;
}

.loader span:nth-child(2) {
    left: 26px;
    animation-delay: 0.2s;
}

.loader span:nth-child(3) {
    left: 39px;
    animation-delay: 0.4s;
}

@keyframes loaderAnimation {
    0%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.7);
        opacity: 0.3;
    }
}
.custom-tooltip {
    display: none;
}
.custom-tooltip-disabled {
    background-color: #001965 !important;
    font-size: 12px;
    font-weight: 500;
    min-width: 250px;
    max-width: 380px;
    left: 0;
    top: -60px;
    // position: absolute;
    border-radius: 8px;

    &.left {
        left: 10px !important;
    }
}

.ant-tooltip-inner {
    background-color: #001965 !important;
    font-size: 12px;
    font-weight: 500;
    min-width: 250px;
    max-width: 380px;
    left: 0;
    top: -60px;
    // position: absolute;
    border-radius: 8px;
}

.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker {
    margin: 20px 0;
    border: none !important;
    width: 100%;
    font-family: 'Quicksand', sans-serif;
}

.react-datepicker__header {
    background-color: white !important;
    border: none !important;
}

.react-datepicker__week {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.current-month {
    color: #001965 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: 'Quicksand', sans-serif !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0 !important;
}

.current-month__month {
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.datepicker {
    width: 100%;
}

.rangepicker {
    height: 42px !important;
}

.datepicker__arrows-right {
    transform: rotate(180deg);
}

.react-datepicker__day-names {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.react-datepicker__day-name {
    color: #808cb2 !important;
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-weight: 500;
}

.react-datepicker__day {
    color: #001965 !important;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
    width: 32px !important;
    height: 32px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.react-datepicker__day:hover {
    background-color: #f5f7fa !important;
    border: none;
    border-radius: 50% !important;
    color: #001965 !important;
}

.react-datepicker__day--today {
    color: #e64a0d !important;
    background-color: white !important;
    border: 1px solid #e64a0d !important;
    border-radius: 50% !important;
}

.ant-picker-cell-today {
    .ant-picker-cell-inner {
        color: #e64a0d !important;
        background-color: white !important;
        border: 1px solid #e64a0d !important;
        border-radius: 50% !important;
        padding: 10px !important;

        &::before {
            border: none !important;
        }
    }
}

.ant-picker-header-prev-btn {
    line-height: 15px !important;
}

.ant-picker-panels {
    border-radius: 40px !important;
}

.ant-picker-content {
    th {
        color: #808cb2 !important;
        font-size: 12px !important;
    }
}

.ant-picker-header {
    border: none !important;
    display: flex !important;
    align-items: center !important;
    padding: 10px 20px 0 20px !important;
}

.ant-picker-header-view {
    color: #001965 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.ant-picker {
    border-radius: 40px;
    height: 42px;
    border: 1px solid #e6e8f0;
    padding: 8px 20px 8px 20px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    transition: all 0.4s;
    margin: 0 0 6px 0 !important;

    &:hover {
        border-color: #adaeb0 !important;
    }
}

.ant-picker-cell {
    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;

    &:hover {
        background-color: #b3bad1 !important;
    }
}

.ant-picker-cell-range-hover-start {
    background-color: #b3bad1 !important;
}

.ant-picker-cell-in-view {
    .ant-picker-cell-inner {
        &:hover {
            background-color: #b3bad1 !important;
            background: #b3bad1 !important;
        }
    }
}

.ant-picker-cell-inner {
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-weight: 600 !important;
}

.ant-picker-cell-selected {
    &::before {
        background-color: #001965 !important;
        height: 32px !important;
    }
}

.ant-picker-cell-range-hover {
    background-color: #808cb2 !important;
}

.ant-picker-cell-range-start {
    padding: 0 !important;
    .ant-picker-cell-inner {
        border-radius: 15px 0 0 15px !important;
        height: 32px !important;
    }
}

.ant-picker-cell-in-view {
    padding: 0 !important;
}

.ant-picker-cell-range-end {
    padding: 0 !important;
    .ant-picker-cell-inner {
        border-radius: 0 15px 15px 0 !important;
        height: 32px !important;
        padding: 0 !important;
    }
}

.ant-picker-cell-in-range {
    width: 32px !important;
    padding: 0 !important;

    &::before {
        background-color: #eaeff5 !important;
        height: 32px !important;
    }
}

.react-datepicker__day--selected {
    background-color: #001965 !important;
    border: 1px solid #001965;
    color: white !important;
    border-radius: 50% !important;
}

.react-datepicker__day--selected:hover {
    background-color: #001965 !important;
    border: 1px solid #001965;
    color: white !important;
    border-radius: 50% !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: white !important;
}

.react-datepicker__day--disabled {
    color: #b3bad1 !important;
}
.react-datepicker__day--disabled:hover {
    color: #b3bad1 !important;
    cursor: context-menu;
    background-color: white !important;
}

.count-wrapper {
    color: var(--texts-icons-primary, #001965);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    width: 149px;
}

.count {
    border-radius: 40px;
    background: var(--backgrounds-additional, #c1d7ec);
    margin: 0 0 0 8px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.calendar-wrapper {
    position: absolute;
    top: 63px;
    z-index: 6;
    border-radius: 24px;
    background: white;
    border: 1px solid var(--borders-lines-accent, #001965);
    padding: 0 20px;
    box-shadow: 0px 4px 40px 0px rgba(4, 22, 71, 0.15);
}

.ant-picker-suffix {
    display: none !important;
}

.calendar-image {
    position: absolute;
    z-index: 2;
    top: 15px;
    left: 20px;
    transition: all 0.3s;

    &.bottom {
        top: 22px;
        transition: all 0.3s;
    }
}

.ant-upload-drag {
    height: 106px !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    justify-content: space-evenly !important;
    border-radius: 26px !important;
    background: white !important;
}

.ant-upload-drag-hover {
    background: var(--backgrounds-secoundary, #f5f7fa) !important;
    border: 1px dashed var(--borders-lines-accent, #001965) !important;
}

.hover-content {
    display: none;
}

.card__additional-info.school-info:hover + .hover-content,
.card__additional-info.school-user-info:hover + .hover-content,
.card__additional-info.tr-info:hover + .hover-content,
.card__name.expense-area:hover + .hover-content,
.card__name.school-name:hover + .hover-content,
.card__name.school-user-name:hover + .hover-content,
.card__name.tr-name:hover + .hover-content {
    display: block;
    position: absolute;
    top: 85px;
    border-radius: 4px;
    border: 1px solid var(--borders-lines-primary, #e6e8f0);
    background: var(--backgrounds-tertiary, #eaeff5);
    padding: 4px 8px;
    z-index: 2;
    width: 230px;
    top: 87px;
    left: 0;
    text-wrap: wrap;
}

.hover-content {
    display: none; /* Hide tooltip by default */
}

.participants__name:hover + .hover-content {
    display: block;
}

.participants__title:hover + .hover-content {
    display: block;
}

.ant-input::placeholder {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--texts-icons-label, #808cb2);
}

.checkbox-title {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.ant-menu {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.ant-pagination-item-active {
    background: #001965 !important;

    a {
        color: white !important;
        font-weight: 400 !important;
    }
}

.hover-content {
    display: none;
    position: absolute;
    top: 40px;
    width: 290px;
    border-radius: 4px;
    border: 1px solid var(--borders-lines-primary, #e6e8f0);
    background: var(--backgrounds-tertiary, #eaeff5);
    padding: 4px 8px;
    z-index: 1;
    font-size: 12px;
    font-weight: 500;
    color: var(--texts-icons-primary, #001965) !important;
}

.ant-avatar {
    img {
        border-radius: 50%;
    }
}

.ant-segmented {
    font-style: normal;
    font-weight: 500;
    color: #4d5e93;
    padding: 4px;
    border-radius: 40px;
    .ant-segmented-group {
        background-color: #f5f7fa;

        .ant-segmented-thumb {
            border-radius: 40px;
            background-color: #001965;
        }
    }

    .ant-segmented-item-selected {
        .ant-segmented-item-label {
            background-color: #001965;
            border-radius: 40px;
            color: white;
            text-decoration: none !important;
            transition: color 0.1s;
        }
    }

    .ant-segmented-item {
        border-radius: 40px;
        transition: color 0.1s;
    }
}

.ant-table-body {
    overflow-y: auto !important;
}

.checkbox_text {
    color: var(--texts-icons-secondary, #4d5e93);
    font-size: 16px;
    font-weight: 500;
}

.supplier_status {
    border-radius: 40px;
    border: 1px solid var(--borders-lines-primary, #e6e8f0);
    background: var(--Backgrounds-Secoundary, #f5f7fa);
    display: inline-block;
    text-wrap: nowrap;
    padding: 4px 12px;

    img {
        margin: 0px 5px -4px 0;
    }
}
.supplier_status_onmodal {
    position: absolute;
    border-radius: 40px;
    border: 1px solid var(--borders-lines-primary, #e6e8f0);
    background: var(--Backgrounds-Secoundary, #fff);
    display: inline-block;
    text-wrap: nowrap;
    padding: 0 12px;
    top: 5px;
    transition: all 0.3s;

    img {
        margin: 0px 5px -4px 0;
    }

    &__image {
        margin: 0 0 -6px 5px !important;
    }

    &.open {
        border-radius: 12px;
        transition: all 0.3s;
        display: flex;
        flex-direction: column;
    }

    &__status {
        margin: 4px 0;
    }
}

.tooltip-light {
    &__row {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__number {
        font-weight: 600;
        font-size: 14px;
    }
}

.ant-switch {
    &:hover {
        background-color: #001965 !important;
    }
}

.note {
    display: flex;
    color: var(--texts-icons-primary, #001965);
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    background: var(--Backgrounds-Tertiary, #eaeff5);
    padding: 10px 20px;
    line-height: 20px;
    margin: 16px 0 40px 0;

    img {
        margin: 0 17px 0 0;
    }
}

.campus-date {
    text-wrap: nowrap;
}

.al-start {
    align-items: flex-start !important;
}
