.dashboard {
    margin: 24px 34px;

    .registration-status {
        display: flex;
        align-items: center;
        color: var(--texts-icons-primary, #001965);
        font-weight: 500;
        margin: 0 0 20px 0;

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            border: 1px solid var(--borders-lines-hover, #b3bad1);
            background: var(--Backgrounds-Secoundary, #f5f7fa);
            padding: 12px 20px;
            width: 33%;
            margin: 0 8px;
        }
        &__title {
            font-size: 16px;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &__subtitle {
            color: var(--texts-icons-secondary, #4d5e93);
            font-size: 14px;
        }

        &__value {
            font-size: 24px;
        }
    }

    .top-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 24px 0;

        &__item {
            width: 15%;
            height: 52px;
            background: #fafbfc;
            margin: 0 4px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            .color {
                width: 10px;
                height: 52px;
                border-radius: 12px 0 0 12px;
                margin: 0 10px 0 -20px;
            }

            .on-track {
                background-color: #098e1d;
            }

            .late {
                background-color: #e80a0f;
            }

            .follow-up {
                background-color: #e3a11f;
            }
        }

        &__wrap {
            display: flex;
            align-items: center;
        }

        &__title {
            color: #4d5e93;
            font-size: 14px;
            font-weight: 500;
        }

        &__value {
            color: #001965;
            font-size: 24px;
            font-weight: 500;

            &.value {
                @media (max-width: 1700px) {
                    font-size: 18px;
                }
            }
        }
    }

    .school_user {
        .top-row__item {
            width: 19%;
        }
    }

    &__title {
        color: #001965;
        font-size: 24px;
        font-weight: 500;
        margin: 0 0 24px 0;
    }

    &__filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__search {
        width: 30%;
    }
    &__add {
        width: 107px !important;

        &.nodata {
            width: 150px !important;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .kanban {
        margin: 16px 0 0 0;
        display: flex;
        align-items: flex-start;
        overflow-y: scroll;

        &__item {
            min-width: 300px;
            margin: 0 16px 0 0;

            &.OR {
                width: 33%;
            }
        }

        &__header {
            background: #f5f7fa;
            padding: 14px 16px;
            border-radius: 12px;
            color: #001965;
            font-size: 20px;
            font-weight: 500;
            margin: 0 0 8px 0;
            display: flex;
        }

        &__number {
            margin: 0 0 0 16px;
            background-color: #c1d7ec;
            color: #001965;
            border-radius: 50%;
            width: 24px;
            align-items: center;
            display: flex;
            justify-content: center;
            font-size: 16px;
        }

        .card {
            border: 1px solid #e6e8f0;
            border-radius: 12px;
            padding: 20px 16px;
            margin: 0 0 16px 0;
            position: relative;
            transition: all 0.3s;

            &:hover {
                background-color: #eaeff5;
                cursor: pointer;
                transition: all 0.3s;
            }

            &__supplier-list {
                background: var(--Backgrounds-Secoundary, #f5f7fa);
                padding: 16px 20px;
            }

            .supplier-item {
                background: var(--Primary-White, #fff);
                border-radius: 8px;
                padding: 12px;
                margin: 12px 0 0 0;

                &:first-child {
                    margin: 0;
                }

                &__title {
                    color: var(--texts-icons-primary, #001965);
                    font-size: 16px;
                    font-weight: 500;
                }

                &__subtitle {
                    color: var(--texts-icons-label, #808cb2);
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            &__arrow {
                width: 25px;
                cursor: pointer;
                transition: all 0.3s;

                &.active {
                    transform: rotate(-180deg);
                    transition: all 0.3s;
                }
            }

            &.OR {
                padding: 0;

                .main {
                    padding: 8px 16px 8px 20px;
                    margin: 0;
                }

                .card__row {
                    padding: 0 16px;
                }

                .top {
                    padding: 20px 16px 0 20px;
                    margin: 0;
                }
            }

            &__value {
                color: var(--texts-icons-primary, #001965);
                font-size: 16px;
                font-weight: 500;
            }

            &__block {
                margin: 16px 0;
                color: var(--texts-icons-secondary, #4d5e93);
                width: 49%;
                padding: 8px 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 10px;

                &.market {
                    background: var(--Backgrounds-Secoundary, #f5f7fa);
                }

                &.gray {
                    background: var(--Backgrounds-Error, #f5f7fa);
                }
                &.red {
                    background: var(--Backgrounds-Error, #fde6e7);
                }
                &.green {
                    background: var(--Backgrounds-Error, #fde6e7);
                }
            }

            &__suppliers {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                color: var(--texts-icons-primary, #001965);
                font-weight: 500;
                background: var(--Backgrounds-Secoundary, #f5f7fa);
                padding: 16px 16px 16px 0;
                border-radius: 0 0 9px 9px;
            }

            &__deadline {
                color: #4d5e93;
                font-size: 14px;
                font-weight: 500;
                margin: 0 0 0 10px;
            }

            &__row {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.top {
                    margin: 0 0 16px 0;
                }
            }

            &__message-icon {
                width: 23px;
            }

            &__task {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #001965;
                font-weight: 500;
                font-size: 16px;
                width: 100px;

                &.Registrations {
                    width: 130px;
                }
            }

            &__number {
                color: white;
                border-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 19px;
                width: 22px;
                margin: 0 0 0 4px;
                font-size: 14px;

                &.blue {
                    background-color: #001965;
                }

                &.grey {
                    background-color: #eaeff5;
                    color: #808cb2;
                    font-weight: 600;
                }

                &.red {
                    background-color: #e80a0f;
                    color: #ffffff;
                }
            }
            &__title {
                color: #001965;
                font-size: 16px;
                font-weight: 500;
            }

            &__status {
                display: flex;
                color: #001965;
                font-size: 14px;
                font-weight: 500;
                padding: 1px 6px 1px 2px;
                border-radius: 10px;

                img {
                    margin: 0 5px 0 0;
                }

                &.on-track {
                    background: #e6f4e8;
                }

                &.follow_up {
                    background: #fcf6e9;
                }

                &.late {
                    background: #fde6e7;
                }
            }

            &__menu {
                position: absolute;
                right: 10px;
                z-index: 2;
                padding: 1px 3px 0 3px;
                transition: all 0.3s;
                border-radius: 50%;

                &:hover {
                    cursor: pointer;
                    background-color: #d2daf1;
                    transition: all 0.3s;
                }
            }

            .main {
                margin: 0 0 16px 0;
                &__row {
                    display: flex;
                    align-items: center;
                    margin: 8px 0;
                }

                &__name {
                    color: #001965;
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0 0 0 6px;
                }
            }
        }

        .notClicable {
            &:hover {
                cursor: context-menu;
                background-color: transparent;
            }
        }
    }
}

.openManage {
    position: absolute;
    top: 40px;
    right: 19px;
    width: 200px;
    max-height: 100px;
    background: white;
    border: 1px solid #001965;
    border-radius: 20px;
    padding: 10px;
    z-index: 4;

    &__item {
        display: flex;
        align-items: center;
        color: #001965;
        font-size: 16px;
        font-weight: 500;
        padding: 9px 17px;
        cursor: pointer;

        &:hover {
            background: #f5f7fa;
            border-radius: 10px;
        }

        &.cancel-tender {
            color: #e80a0f;
        }

        img {
            padding: 0 10px 0 0;
        }
    }
}

.su {
    width: 910px;
    margin: 0 0 16px 0;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e8f0;
    border-radius: 12px;
    cursor: pointer;

    &.not-active {
        background-color: #fafbfc;
        cursor: context-menu;
    }

    .element__number {
        &.blue {
            background-color: #001965;
            color: white;
            min-width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__title {
        color: #001965;
        font-size: 24px;
        font-weight: 500;
    }

    &__item {
        &.left {
            width: 60%;
        }

        &.phase {
            width: 30%;
            border: 1px solid #e6e8f0;
            border-radius: 12px;
            height: 62px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 15px;
            color: #001965;
            font-size: 16px;
            font-weight: 500;

            span {
                color: #808cb2;
            }
        }
    }

    &__wrap {
        display: flex;
        margin: 16px 0 0 0;
    }

    &__id {
        color: #808cb2;
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 0 16px;

        span {
            color: #001965;
        }
    }

    &__top-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__bottom-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .element {
        &__title {
            color: #808cb2;
        }

        &__value {
            color: #001965;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            margin: 12px 0 0 0;

            img {
                margin: 0 5px 0 0;
            }
        }

        &__number {
            background-color: #eaeff5;
            color: #808cb2;
            padding: 0 4px;
            border-radius: 50%;

            &.red {
                background-color: #e80a0f;
                color: white;
                padding: 0 7px;
            }
        }
    }
}
